.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 26px;
    outline: none;
  }
  .switch input {
    position: absolute;
    top: -99999px;
    left: -99999px;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: #9ae6b4;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #9ae6b4;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
  }


  .tickDiv{
    width: 20px;
    height: 20px;
    display:inline-block;
    flex-grow: 0;
    margin: 1px 11.2px 0 0;
    padding: 5.5px 4px 5.1px 4.5px;
    opacity: 0.1;
    background-color: #fff;
  }
  .tickDiv div {
    width: 11.5px;
    height: 9.4px;
    flex-grow: 0;
    background-color: #fff;
}
.tickDiv div:before {
    content: '✓';
}